import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import './App.css'; // Import Tailwind CSS and custom styles

const ContactForm = ({product ,backgroundColor}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const user = useSelector((state) => state?.user?.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');

  // Fetch the primary color on component mount
  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        if (data.success) setPrimaryColor(data.data.primaryColor);
      } catch (error) {
        console.error('Error fetching header color:', error);
      }
    };
    fetchHeaderColor();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Send WhatsApp message
  async function tosendWhatsappMessage() {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${process.env.REACT_APP_USER_ID}`
      );
      const data = await response.json();

      let phoneNumber = formData.mobile;
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(-10); // Take the last 10 digits
      }
      phoneNumber = "91" + phoneNumber;

      await fetch(`${data.data.api}/api/wpbox/sendtemplatemessage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: data.data.token,
          phone: phoneNumber,
          template_name: data.data.template_name,
          template_language: data.data.template_language,
        }),
      });
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload on submit
    setIsSubmitting(true);

    const emailData = {
      to: formData.email,
      subject: `New Website Visitor Enquiry from ${formData.name}`,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
      html: `
        <div style="font-family: Arial, sans-serif; padding: 20px;">
          <h2>New Visitor Enquiry</h2>
          <p><strong>Name:</strong> ${formData.name}</p>
          <p><strong>Mobile:</strong> ${formData.mobile}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
        </div>
      `,
      product
    };
 

    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(emailData),
        }
      );

      const data = await response.json();
      toast.success('Email sent successfully!');
      setFormData({ name: '', email: '', mobile: '' });
      tosendWhatsappMessage();
    } catch (error) {
      // console.error('Error sending email:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsPopupOpen(true)}
        className="btn  py-2 px-6 rounded-full shadow-lg transition-all  "
        style={{ backgroundColor: primaryColor,color:backgroundColor }}
      >
        Enquiry Now
      </button>

      <Toaster />

      {isPopupOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setIsPopupOpen(false)} // Close modal on background click
        >
          <div
            className="bg-white p-8 rounded-lg shadow-lg relative max-w-md w-full"
            onClick={(e) => e.stopPropagation()} // Prevent modal close on click inside
          >
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
              onClick={() => setIsPopupOpen(false)}
            >
              &times;
            </button>
            <h3 className="text-md font-bold text-black mb-4">
              Get More Details on Email/WhatsApp
            </h3>

            <form className="space-y-6" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name*"
                className="w-full border-b-2 bg-gray-100 py-1 px-4 focus:outline-none focus:border-blue-500"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email*"
                className="w-full border-b-2 bg-gray-100 py-1 px-4 focus:outline-none focus:border-blue-500"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile*"
                className="w-full border-b-2 bg-gray-100 py-1 px-4 focus:outline-none focus:border-blue-500"
                value={formData.mobile}
                onChange={handleChange}
                required
              />

              <button
                type="submit"
                className={`w-full py-2 px-6 rounded-full shadow-lg transition-all ${
                  isSubmitting
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-white hover:bg-black hover:text-white'
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
