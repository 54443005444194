import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayINRCurrency from '../helpers/displayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import addToCart from '../helpers/addToCart'
import Context from '../context'
import scrollTop from '../helpers/scrollTop'
import ContactForm from './Enquiry'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'





const CategroyWiseProductDisplay = ({category, heading,owner}) => {
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(true)
    const loadingList = new Array(13).fill(null)
    const user = useSelector(state => state?.user?.user);
    const { fetchUserAddToCart } = useContext(Context)
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [formData, setFormData] = useState({ name: "", email: "", mobile: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleAddToCart = async(e,id)=>{
       await addToCart(e,id)
       fetchUserAddToCart()
    }

    const handlePopupOpen = () => setIsPopupOpen(true);
    const handlePopupClose = () => setIsPopupOpen(false);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    async function tosendWhatsappMessage() {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${process.env.REACT_APP_USER_ID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          // throw new Error('Failed to fetch WhatsApp configuration.');
        }
        const data = await response.json();
        let phoneNumber = formData.mobile;
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.slice(-10); // Take the last 10 digits
        }
        phoneNumber = "91" + phoneNumber;
        const response1 = await fetch(
          `${data.data.api}/api/wpbox/sendtemplatemessage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: data.data.token,
              phone: phoneNumber, // Use the mobile number from form data
              template_name: data.data.template_name,
              template_language: data.data.template_language,
            }),
          }
        );
  
        // console.log("whatsapp reesp", response1)
      } catch (error) {
        // toast.success('Error fetching WhatsApp configuration.');
      }
    }
  
    const highlights = data?.shortdescription?.split(",") || [];
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
  
      const emailData = {
        to: formData.email,
        subject: `Enquiry from ${formData.name}`,
        text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
        html: `<p><strong>Name:</strong> ${formData.name}</p><p><strong>Mobile:</strong> ${formData.mobile}</p><p><strong>Email:</strong> ${formData.email}</p>`,
        product: data,
      };
  
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(emailData),
          }
        );
        const data = await response.json();
        // console.log('Response Data:', data);
   
          toast.success("Email sent successfully!");
          setFormData({ name: "", email: "", mobile: "" });
          setIsPopupOpen(false);
          setIsSubmitting(false);
          tosendWhatsappMessage();
          setIsSubmitting(false);
      
      } catch (error) {
        // console.log("ttttttt",error)
        toast.error(error);
      } finally {
        setIsSubmitting(false);
      }
    };
  
    useEffect(() => {
        const fetchHeaderColor = async () => {
          try {
            const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
            const data = await response.json();
    
            if (data.success) {
              setPrimaryColor(data.data.primaryColor);
              setSecondaryColor(data.data.secondaryColor);
              setBackgroundColor(data.data.backgroundColor);
            }
          } catch (error) {
            // Handle error appropriately
          }
        };
        fetchHeaderColor();
      }, [`${process.env.REACT_APP_USER_ID}`]);
    

    const fetchData = async() =>{
        setLoading(true)
        const categoryProduct = await fetchCategoryWiseProduct(category,`${process.env.REACT_APP_USER_ID}`);
        // console.log("categoryproduct",categoryProduct);
        setLoading(false)

        // console.log("horizontal data",categoryProduct.data)
        setData(categoryProduct?.data)
    }

    useEffect(()=>{
        fetchData()
    },[category])

    function extractNumberAndText(input) {
        const regex = /^(\d+)\s*(.*)$/;
        const match = input.match(regex);
    
        if (match) {
          const number = match[1];
          const text = match[2];
          return { number, text };
        }
    
        return null;
      }
    
    const displayINRCurrency1 = (num) => {

        const extractedData = extractNumberAndText(num);
        const extractedNumber = extractedData ? extractedData.number : num;
        const extractedText= extractedData ? extractedData.text:'';
      
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
        });
    
        return `Starting From: ${formatter.format(extractedNumber)} `+ extractedText;
      };
     

  return (
    <div className='container mx-auto px-4 my-6 relative font-montserrat ' style={{backgroundColor:backgroundColor}}> 

          <h3 className='text-xl' style={{color:primaryColor}}>Similar Products : -</h3>

                
           <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,320px))] justify-start md:gap-6 overflow-x-scroll scrollbar-none transition-all'>
           {

                loading ? (
                    loadingList.map((product,index)=>{
                        return(
                            <div className='w-full min-w-[280px]  md:min-w-[320px] max-w-[280px] md:max-w-[320px]  bg-white rounded-sm shadow '>
                                <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'>
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                                    <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200  py-2'></p>
                                    <div className='flex gap-3'>
                                        <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
                                        <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
                                    </div>
                                    <button className='text-sm  text-white px-3  rounded-full bg-slate-200  py-2 animate-pulse'></button>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    data.map((product,index)=>{
                        return(
                            <Link to={"/product/"+product?.slug} className='w-full min-w-[280px]  md:min-w-[320px] max-w-[280px] md:max-w-[320px]   rounded-sm shadow ' onClick={scrollTop}>
                                <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                    <img   src={`https://builder.biztoindia.in/${product.productImage[0]}`}   className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'/>
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 ' style={{color:primaryColor}}>{product?.productName}</h2>
                                    <p className='capitalize' style={{color:secondaryColor}}>{product?.category}</p>
                                   { data?.isPriceVisible &&  <div className='flex gap-3'>
                                        <p className=' font-medium' 
                                          style={{
                                            color: `${secondaryColor} `,
                                            
                                         }}
                                        >{ displayINRCurrency1(product?.price) }</p>
                                        
                                    </div> }
                                    <div className='flex items-center gap-3 my-2'>
               
                                    <button
                onClick={handlePopupOpen}
                className=" hover:bg-red-600 transition-all  px-4 mt-3 py-2 w-60 rounded-full"
                style={{backgroundColor:primaryColor, color:   backgroundColor}}

              >
                Enquiry Now
              </button>
             </div>
 
                                </div>
                            </Link>
                        )
                    })
                )
                
            }
           </div>
           {isPopupOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 sm:px-6">
    <div className="bg-white rounded-xl shadow-lg p-8 max-w-lg w-full relative sm:mx-auto sm:my-6">
      <button
        onClick={handlePopupClose}
        className="absolute top-2 right-2 text-2xl font-bold text-gray-600 hover:text-gray-800 transition-colors"
      >
        &times;
      </button>
      <h2 className="text-xl font-semibold mb-6 text-center text-gray-800">
        Enquiry Form
      </h2>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div>
          
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
            placeholder="Enter your name"
            required
          />
        </div>

        <div>
           
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
            placeholder="Enter your email"
            required
          />
        </div>

        <div>
           
          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
            placeholder="Enter your mobile number"
            required
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold px-6 py-3 rounded-full shadow-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 ease-in-out transform hover:scale-105"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Enquiry"}
          </button>
        </div>
      </form>
    </div>
  </div>
)}

    </div>
  )
}

export default CategroyWiseProductDisplay