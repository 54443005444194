import React, { useState, useEffect } from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { AiOutlinePhone } from 'react-icons/ai';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
 
const FloatingIcons = () => {
  const user = useSelector(state => state?.user?.user);
  const [settings, setSettings] = useState({
    showWhatsAppIcon: true,
    showCallIcon: true,
    whatsappNumber: '',
    callNumber: '',
  });

  useEffect(() => {
    // Fetch visibility settings for the icons
    const fetchSettings = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-setting/${process.env.REACT_APP_USER_ID}`); // Replace with your API endpoint
        if (!response.ok) {
          // throw new Error('Failed to fetch settings');
        }
        const data = await response.json();
        // console.log("dd",data)
        setSettings(data);
      } catch (error) {
        // toast.error('Failed to fetch settings');
      }
    };

    fetchSettings();
  }, [ ]);

  return (
    <div className="fixed bottom-0 right-0 mb-4 mr-4 flex flex-col items-center space-y-4">
      <Toaster />
      {settings?.showWhatsAppIcon && (
        <a
          href={`https://wa.me/${settings?.whatsappNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
          aria-label="WhatsApp"
        >
          <AiOutlineWhatsApp size={32} />
        </a>
      )}
      {settings?.showCallIcon && (
        <a
          href={`tel:${settings?.callNumber}`}
          className="bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
          aria-label="Call"
        >
          <AiOutlinePhone size={32} />
        </a>
      )}
    </div>
  );
};

export default FloatingIcons;
