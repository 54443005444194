import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FaqShow = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null); // To track the currently active FAQ
  const user = useSelector(state => state?.user?.user);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  // Fetch FAQs from the API
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-faq/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        setFaqs(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchFaqs();
  }, [user?._id]);

  // Fetch color settings
  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  // Toggle FAQ visibility
  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between active and inactive states
  };

  return (
    <div className="min-h-screen p-4 flex flex-col items-center" style={{ backgroundColor: backgroundColor }}>
      <h1 className="text-2xl font-bold mb-6" style={{ color: primaryColor }}>Frequently Asked Questions</h1>

      <div className="w-full max-w-4xl space-y-4">
        {faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div key={faq._id} className="bg-white p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h2 className="text-xl font-semibold">{faq.question}</h2>
                <span className="text-primaryColor">
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              </div>

              {/* Toggle answer based on the activeIndex */}
              {activeIndex === index && (
                <p className="mt-4 text-gray-700 transition duration-300 ease-in-out">
                  {faq.answer}
                </p>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-600">No FAQs available</p>
        )}
      </div>
    </div>
  );
};

export default FaqShow;
