import React, { useState, useEffect } from 'react';
import BannerProduct from '../components/BannerProduct';
import VerticalCardProduct1 from '../components/VerticalCardProduct1';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import OurClients from './ourclients';
import TestimonialsUi1 from './Testimonials1';
import SummaryApi from '../common'; // Assuming this has the API endpoint
import Herosection from './Herosection';
import HomeGallery from './HomeGallery';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-200 border-t-gray-800 rounded-full animate-spin"></div>
  </div>
);

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector(state => state?.user?.user);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        setAboutData(data);
      } catch (error) {
        // console.error("Error fetching About Us data:", error);
      }
    };
    fetchAboutData();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        console.error("Error fetching header color:", error);
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${SummaryApi.allcategory.url}/${process.env.REACT_APP_USER_ID}`,
          {
            method: SummaryApi.allcategory.method,
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const responseData = await response.json();
        if (responseData.success) {
          setCategories(responseData.categories);
        } else {
          toast.success(responseData.message);
        }
      } catch (error) {
        // console.error("Error fetching categories:", error);
        // toast.error("Error fetching categories");
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, [`${process.env.REACT_APP_USER_ID}`]);
 const uri= `https://builder.biztoindia.in/${categories[0]?.image}`
  return (
    <div style={{ backgroundColor }} className="font-montserrat">
      <Helmet>
        <title>{`Home | ${aboutData?.companyName || ''}`}</title>
        <meta
          name="description"
          content="Welcome to our website. Discover a wide variety of products across different categories."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href={window.location.href} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
   
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={`Home | ${aboutData?.companyName}`} />
        <meta property="og:description" content="Discover a wide variety of products across different categories." />
        <meta property="og:image" content={uri} /> {/* Update with correct path */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Home | ${aboutData?.companyName}`} />
        <meta name="twitter:description" content="Discover a wide variety of products across different categories." />
        <meta name="twitter:image" content={uri} /> {/* Update with correct path */}
     
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <>
          <BannerProduct />
          <Herosection/>
          {categories?.map((cat) => (
            <VerticalCardProduct1 key={cat.value} category={cat.value} heading={cat.label} />
          ))}
          
           <HomeGallery/> 
           
          
          <OurClients companyName={aboutData?.companyName} />
         
         
          <TestimonialsUi1
            companyName={aboutData?.companyName}
            backgroundColor={backgroundColor}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
          
        </>
      )}
    </div>
  );
};

export default Home;
