import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const Sitemap = () => {
    const [sitemapLinks, setSitemapLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
      const fetchAboutData = async () => {
        try {
          const response = await fetch(
            `https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`
          );
          const data = await response.json();
          setAboutData(data);
        } catch (error) {
          // console.error("Error fetching About Us data:", error);
        }
      };
      fetchAboutData();
    }, [`${process.env.REACT_APP_USER_ID}`]);
    useEffect(() => {
        const fetchSitemapPath = async () => {
            try {
                const userId = process.env.REACT_APP_USER_ID; // Replace with actual user ID if needed
                const response = await fetch("https://builder.biztoindia.in/api/sitemap", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ userId }),
                });

                const data = await response.json();
                      console.log("hjghg",data.sitemapPath)
                if (response.ok && data.sitemapPath) {
                    setSitemapLinks(data.sitemapPath);
                } else {
                    // console.error("Sitemap links not found in the response.");
                }
            } catch (error) {
                // console.error("Error fetching sitemap path:", error);
            } finally {
                setLoading(false); // Set loading to false in either case
            }
        };

        fetchSitemapPath();
    }, []);

    // Function to categorize links
    const categorizeLinks = (links) => {
        const categorized = {
            Products: [],
            Blogs: [],
            Others: [],
        };

        links.forEach((link) => {
            if (link.includes("product")) {
                categorized.Products.push(link);
            } else if (link.includes("blogs")) {
                categorized.Blogs.push(link);
            } else {
                categorized.Others.push(link);
            }
        });

        return categorized;
    };

    const categorizedLinks = categorizeLinks(sitemapLinks);

    return (
        <div className="max-w-4xl mx-auto p-4">
             <Helmet>
             <title>{`Sitemap | ${aboutData?.companyName || ''}`}</title>
             </Helmet>
            <h1 className="text-3xl font-bold text-center mb-6">Sitemap</h1>
            {loading ? (
                <p className="text-center text-gray-500">Loading...</p>
            ) : (
                <div className="grid grid-cols-1  gap-6">
                    {Object.entries(categorizedLinks).map(([category, links]) => (
                        <div key={category} className="bg-white p-4 rounded shadow">
                            <h2 className="text-xl font-semibold mb-4">{category}</h2>
                            {links.length > 0 ? (
                                <ul className="space-y-2">
                                    {links.map((link, index) => (
                                        <li key={index} className="rounded-lg overflow-hidden transition-transform transform hover:scale-105">
                                            <a 
                                                href={link} 
                                                target="_blank" 
                                                rel="noopener noreferrer" 
                                                className="block p-3 text-gray-700 hover:text-blue-500 hover:bg-gray-100 transition-colors duration-300 whitespace-normal break-words"
                                            >
                                                {link}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-gray-500">
                                We appreciate your patience as we continue to work on this. Updates will be provided shortly.
                              </p>
                              
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Sitemap;
