import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast'; // Import react-hot-toast
import { useSelector } from 'react-redux';

const ShippingPolicyShow = () => {
  const [shippingContent, setShippingContent] = useState(''); // State for shipping content
  const [error, setError] = useState('');
  const user = useSelector((state) => state?.user?.user);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null); // State for last updated date

  useEffect(() => {
    const fetchShippingContent = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/getshipping?owner=${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data) {
          setShippingContent(data.content); // Set the shipping content
          setLastUpdated(data.lastUpdated); // Set last updated date
          toast.dismiss(); // Dismiss loading toast
        }
      } catch (error) {
        // setError('Error fetching shipping policy content');
        // toast.error('Error fetching content'); // Show error notification
      }
    };

   
      fetchShippingContent();
    
  }, [`${process.env.REACT_APP_USER_ID}`]);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        console.error('Error fetching header color:', error);
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  return (
    <div style={{ backgroundColor: backgroundColor }}>
      <div className="max-w-4xl mx-auto p-6 rounded-lg">
        <Toaster position="top-right" /> {/* Add Toaster to show toast notifications */}

        <h1 className="text-4xl font-bold text-center mb-6" style={{ color: primaryColor }}>Shipping Policy</h1>

        {lastUpdated && (
          <p className="text-center mb-4" style={{ color: secondaryColor }}>
            Last updated on: {new Date(lastUpdated).toLocaleDateString()}
          </p>
        )}

        <div className="shipping-content leading-relaxed" style={{ color: secondaryColor }}>
          {shippingContent ? (
            <div dangerouslySetInnerHTML={{ __html: shippingContent }} className="prose lg:prose-xl max-w-none" />
          ) : (
            <p className="text-center">No shipping policy content available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicyShow;
