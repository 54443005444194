import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import productCategory from '../helpers/productCategory';
import VerticalCard from '../components/VerticalCard';
import SummaryApi from '../common';
import { useSelector } from 'react-redux';

const CategoryProduct = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const urlSearch = new URLSearchParams(location.search);
    const urlCategoryListinArray = urlSearch.getAll("category");
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');

    const urlCategoryListObject = {};
    urlCategoryListinArray.forEach(el => {
        urlCategoryListObject[el] = true;
    });

    const [selectCategory, setSelectCategory] = useState(urlCategoryListObject);
    const [filterCategoryList, setFilterCategoryList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sortBy, setSortBy] = useState("");
    const user = useSelector(state => state?.user?.user);

    const fetchData = async () => {
        const response = await fetch(SummaryApi.filterProduct.url, {
            method: SummaryApi.filterProduct.method,
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                category: filterCategoryList
            })
        });

        const dataResponse = await response.json();
        setData(dataResponse?.data || []);
    };

    const handleSelectCategory = (e) => {
        const { value, checked } = e.target;
        setSelectCategory((prev) => ({
            ...prev,
            [value]: checked
        }));
    };

    useEffect(() => {
        fetchData();
    }, [filterCategoryList]);

    useEffect(() => {
        const arrayOfCategory = Object.keys(selectCategory).map(categoryKeyName => {
            return selectCategory[categoryKeyName] ? categoryKeyName : null;
        }).filter(el => el);

        setFilterCategoryList(arrayOfCategory);

        const urlFormat = arrayOfCategory.map(el => `category=${el}`).join("&&");
        navigate("/product-category?" + urlFormat);
    }, [selectCategory]);

    useEffect(() => {
        const fetchHeaderColor = async () => {
            try {
                const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
                const data = await response.json();

                if (data.success) {
                    setPrimaryColor(data.data.primaryColor);
                    setSecondaryColor(data.data.secondaryColor);
                    setBackgroundColor(data.data.backgroundColor);
                }
            } catch (error) {
                // Handle error appropriately
            }
        };
        fetchHeaderColor();
    }, [`${process.env.REACT_APP_USER_ID}`]);

    const handleOnChangeSortBy = (e) => {
        const { value } = e.target;
        setSortBy(value);

        if (value === 'asc') {
            setData(prev => prev.sort((a, b) => a.sellingPrice - b.sellingPrice));
        }

        if (value === 'dsc') {
            setData(prev => prev.sort((a, b) => b.sellingPrice - a.sellingPrice));
        }
    };

    useEffect(() => { }, [sortBy]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${SummaryApi.allcategory.url}/${process.env.REACT_APP_USER_ID}`, {
                    method: SummaryApi.allcategory.method,
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();

                if (responseData.success) {
                    setCategories(responseData.categories);
                } else {
                    console.error(responseData.message);
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, [`${process.env.REACT_APP_USER_ID}`]);

    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 lg:grid-cols-[10%,1fr] gap-4">
                {/* Left side (Categories - Fixed) */}
                <div className="bg-white p-2 min-h-[calc(100vh-120px)]   top-4 overflow-y-auto">
                    {data?.isPriceVisible && (
                        <div>
                            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
                                Sort by
                            </h3>
                            <form className="text-sm flex flex-col gap-2 py-2">
                                <div className="flex items-center gap-3">
                                    <input
                                        type="radio"
                                        name="sortBy"
                                        checked={sortBy === "asc"}
                                        onChange={handleOnChangeSortBy}
                                        value={"asc"}
                                    />
                                    <label>Price - Low to High</label>
                                </div>
                                <div className="flex items-center gap-3">
                                    <input
                                        type="radio"
                                        name="sortBy"
                                        checked={sortBy === "dsc"}
                                        onChange={handleOnChangeSortBy}
                                        value={"dsc"}
                                    />
                                    <label>Price - High to Low</label>
                                </div>
                            </form>
                        </div>
                    )}
                    <div>
                        <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
                            Categories
                        </h3>
                        <form className="text-sm flex flex-col gap-2 py-2">
                            {categories?.map((categoryName, index) => (
                                <div className="flex items-center gap-3" key={index}>
                                    <input
                                        type="checkbox"
                                        name={"category"}
                                        checked={selectCategory[categoryName?.value]}
                                        value={categoryName?.value}
                                        id={categoryName?.value}
                                        onChange={handleSelectCategory}
                                    />
                                    <label htmlFor={categoryName?.value}>
                                        {categoryName?.label}
                                    </label>
                                </div>
                            ))}
                        </form>
                    </div>
                </div>
    
                {/* Right side (Products - Scrollable with page) */}
                <div className="px-4 "  style={{ backgroundColor }}>
                    <p className="font-medium text-slate-800 text-lg my-2">
                        Search Results: {data.length}
                    </p>
                    <div>
                        {data.length !== 0 && !loading && (
                            <VerticalCard data={data} loading={loading} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default CategoryProduct;
