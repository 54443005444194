import React, { useState, useEffect } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import { Helmet } from 'react-helmet'; // Import react-helmet
import { toast } from 'react-toastify';

const TestimonialsUi = ({companyName}) => {
  const [testimonials, setTestimonials] = useState([]);
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {}
    };
    fetchHeaderColor();
  }, []);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        setAboutData(data);
      } catch (error) {}
    };
    fetchAboutData();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const fetchTestimonials = async () => {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/items/${process.env.REACT_APP_USER_ID}`
      );
      if (response.ok) {
        const data = await response.json();
        setTestimonials(data);
      } else {
        throw new Error('Error fetching testimonials');
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const limitDescription = (description) => {
    return description.length > 250 ? `${description.slice(0, 250)}...` : description;
  };

  return (
    <>
      <Helmet>
        <title>{`Testimonials | ${aboutData?.companyName}`}</title>
        <meta
          name="description"
          content="Read what our happy clients have to say about our services. Stay tuned for more testimonials."
        />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content="Discover the experiences of our clients and their satisfaction with our services."
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="py-16  font-lub" style={{ backgroundColor }}>
        <div className="container mx-auto px-4 md:px-12">
          {testimonials?.length > 0 && (
            <h2
              className="text-center text-3xl md:text-4xl font-bold mb-8 md:mb-12"
              style={{ color: primaryColor }}
            >
              What Our Clients Say
            </h2>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {testimonials.map((testimonial) => (
              <div key={testimonial._id} className="bg-white rounded-lg shadow-lg p-6 md:p-8 flex items-center transition-shadow duration-300 hover:shadow-2xl">
                <div className="w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 mr-4 md:mr-6">
                  <img
                    src={`https://builder.biztoindia.in/${testimonial?.image}`}
                    alt={testimonial.name}
                    className="rounded-full object-cover w-full h-full shadow-md"
                  />
                </div>
                <div className="flex-1">
                  <FaQuoteLeft
                    className="text-2xl md:text-3xl mb-4"
                    style={{ color: primaryColor }}
                  />
                  <p
                    className="text-sm md:text-md mb-4 italic"
                    style={{ color: secondaryColor }}
                  >
                    "{limitDescription(testimonial.description)}"
                  </p>
                  <h3 className="text-lg font-semibold" style={{ color: primaryColor }}>
                    {testimonial.name}
                  </h3>
                  <p style={{ color: secondaryColor }}>{testimonial.designation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsUi;
