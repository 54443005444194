import React, { useContext, useEffect, useState } from 'react';
import scrollTop from '../helpers/scrollTop';
import displayINRCurrency from '../helpers/displayCurrency';
import Context from '../context';
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

const VerticalCard = ({ loading, data = [] }) => {
  const loadingList = new Array(13).fill(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchUserAddToCart } = useContext(Context);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);


  async function tosendWhatsappMessage() {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${process.env.REACT_APP_USER_ID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // throw new Error('Failed to fetch WhatsApp configuration.');
      }
      const data = await response.json();
      let phoneNumber = formData.mobile;
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(-10); // Take the last 10 digits
      }
      phoneNumber = "91" + phoneNumber;

      const response1 = await fetch(
        `https://wa.biztoindia.com/api/wpbox/sendtemplatemessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: data.data.token,
            phone: phoneNumber, // Use the mobile number from form data
            template_name: data.data.template_name,
            template_language: data.data.template_language,
          }),
        }
      );

      // console.log("whatsapp reesp", response1)
    } catch (error) {
      // toast.success('Error fetching WhatsApp configuration.');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const emailData = {
      to: formData.email,
      subject: 'Enquiry from ' + formData.name,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
      html: `<p><strong>Name:</strong> ${formData.name}</p><p><strong>Mobile:</strong> ${formData.mobile}</p><p><strong>Email:</strong> ${formData.email}</p>`,
      product: selectedProduct,
    };

    try {
      const response = await fetch(`https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      toast.success('Email sent successfully!');
      setFormData({ name: '', email: '', mobile: '' });
      setIsPopupOpen(false);
      setIsSubmitting(false);
      tosendWhatsappMessage();
    } catch (error) {
      toast.error('Error sending email.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Extract number and text from price
  const extractNumberAndText = (input) => {
    const regex = /^(\d+)\s*(.*)$/;
    const match = input?.match(regex);

    if (match) {
      const number = match[1];
      const text = match[2];
      return { number, text };
    }

    return { number: input, text: '' };
  };

  return (
    <div className='grid grid-cols-[repeat(auto-fit,minmax(260px,300px))] justify-center md:justify-start md:gap-4 overflow-x-scroll scrollbar-none transition-all'>
      <Toaster position="top-right" reverseOrder={false} />
      {loading ? (
        loadingList.map((product, index) => (
          <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
            <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'></div>
            <div className='p-4 grid gap-3'>
              <h2 className='font-medium text-base md:text-lg text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
              <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
              <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
            </div>
          </div>
        ))
      ) : (
        data.map((product, index) => {
          const { number: extractedNumber, text: extractedText } = extractNumberAndText(product?.price);

          return (
            <Link to={'/product/' + product?.slug} key={index} className='w-full min-w-[280px] md:min-w-[300px] max-w-[280px] md:max-w-[300px] bg-white rounded-sm shadow' onClick={scrollTop}>
              <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                <img   src={`https://builder.biztoindia.in/${product.productImage[0]}`} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt={product?.productName} />
              </div>
              <div className='p-4 grid gap-3'>
                <h2 className='font-medium text-base md:text-lg ' style={{color:primaryColor}}>{product?.productName}</h2>
                <p className='capitaliz' style={{color:secondaryColor}}>{product?.category}</p>
                {product?.isPriceVisible && (
                  <div className="flex gap-3">
                    <p className="text-gray-800">
                      <span className="font-bold" style={{ color: `${secondaryColor} ` }}>
                        {displayINRCurrency(extractedNumber)}
                      </span>
                      <span className="text-gray-600">{extractedText}</span>
                    </p>
                  </div>
                )}
                <button
               className=" hover:bg-blue-600 transition-all duration-300 ease-in-out text-white py-2 px-4 rounded-full"
               style={{
                 backgroundColor: `${primaryColor} `,
               }}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedProduct(product);
                    setIsPopupOpen(true);
                  }}
                >
                  Enquire Now
                </button>
              </div>
            </Link>
          );
        })
      )}

      {/* Enquiry Popup */}
      {isPopupOpen && selectedProduct && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
          <div className='bg-white rounded-lg shadow-lg p-8 max-w-md w-full relative'>
            <button className='absolute top-4 right-4 text-gray-500 hover:text-gray-800' onClick={() => setIsPopupOpen(false)}>
              &times;
            </button>
            <h3 className='text-md font-bold text-black mb-4'>Get More Details on Email/WhatsApp</h3>
            <form className='space-y-6' onSubmit={handleSubmit}>
              <input
                type='text'
                name='name'
                placeholder='Name*'
                className='form-input w-full border-b-2 py-2 px-4'
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type='email'
                name='email'
                placeholder='Email*'
                className='form-input w-full border-b-2 py-2 px-4'
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type='tel'
                name='mobile'
                placeholder='Mobile*'
                className='form-input w-full border-b-2 py-2 px-4'
                value={formData.mobile}
                onChange={handleChange}
                required
              />
              <button type='submit'  className="bg-white border border-black hover:bg-black hover:text-white hover:border-red transition-all duration-300 ease-in-out text-black py-2 px-6 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 disabled:opacity-50 w-full" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerticalCard;
