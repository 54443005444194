import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast'; 
import 'tailwindcss/tailwind.css'; 
import { useSelector } from "react-redux";
import  blogComingSoonImage from "../assest/comming.jpg"
const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const user = useSelector(state => state?.user?.user);
  const [aboutData, setAboutData] = useState(null); // Store about data

  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);




  useEffect(() => {
  
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        setAboutData(data);
      } catch (error) {
        console.error('Error fetching About Us data:', error);
      }
    };

    // Fetch blog posts from the server
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/blogss/${process.env.REACT_APP_USER_ID}`); // Adjust the endpoint as needed
        if (response.ok) {
          const data = await response.json();
          // console.log("duf",data);
          setBlogs(data);
          toast.success("Blogs loaded successfully!"); // Show success notification
        } else {
          throw new Error('Failed to fetch blogs');
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
    fetchAboutData();
  }, [process.env.REACT_APP_USER_ID]);

  // Function to format the date in Indian Standard Time (IST)
  const formatDateToIST = (date) => {
    const options = {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: 'Asia/Kolkata',
    };
    return new Date(date).toLocaleDateString('en-IN', options);
  };

  // Function to strip HTML tags from content
  const stripHtmlTags = (htmlString) => {
    const parser = new DOMParser();
    const parsed = parser.parseFromString(htmlString, 'text/html');
    return parsed.body.textContent || ""; 
  };

  const pageTitle = `Blog Posts | ${aboutData?.companyName}`;

  const pageDescription = "Discover insightful blog posts on a variety of topics. Read and explore now!";
  const siteUrl = window.location.href;
  const defaultImage = "/default-thumbnail.jpg"; // Fallback image

  return (
    <>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={siteUrl} />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={siteUrl} />
        {/* <meta property="og:image" content={blogs.length ? `https://builder.biztoindia.in/${blogs[0]?.thumbnail?.path}` : defaultImage} /> */}
        <meta name="robots" content="index, follow" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        {/* <meta name="twitter:image" content={blogs.length ? `https://builder.biztoindia.in/${blogs[0]?.thumbnail.path}` : defaultImage} /> */}

        {/* Structured Data - JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            name: pageTitle,
            description: pageDescription,
            url: siteUrl,
            author: {
              "@type": "Person",
              name: "Your Name",
              url: siteUrl
            },
            blogPost: blogs.map((blog) => ({
              "@type": "BlogPosting",
              headline: blog.title,
              datePublished: blog.createdAt,
              description: stripHtmlTags(blog.content).substring(0, 100),
              url: `${siteUrl}/blogs/${blog._id}`,
              image: blog.thumbnail ? `https://builder.biztoindia.in/${blog.thumbnail.path}` : defaultImage,
              author: {
                "@type": "Person",
                name: blog.authorName
              }
            }))
          })}
        </script>
      </Helmet>

      <div className="container mx-auto p-4 font-lub  " style={{backgroundColor:backgroundColor}}>
        <h1 className="text-3xl font-bold mb-6 text-center"  style={{
          color: `${primaryColor} `,
          
       }}>Blog Posts</h1>

        {/* Display "No blog is available" if blogs array is empty */}
        {blogs.length === 0 ? (
          <div className="text-center text-gray-600 py-10 px-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
             <h2 className="text-2xl font-bold mb-4">Our Blog is Coming Soon</h2>
             <p className="mb-4">We are working on bringing you exciting content. Stay tuned for updates on our blog!</p>
             <img src={blogComingSoonImage} alt="Blog Coming Soon" className="w-full h-auto rounded-lg shadow-md" />
           </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogs?.map((blog) => (
              <Link
                to={`/blogs/${blog?.slug}`}
                key={blog._id}
                className=" p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow" style={{backgroundColor:backgroundColor}}
              >
                <img
                  src={`https://builder.biztoindia.in/${blog?.thumbnail?.path}`} // Adjust path as needed
                  alt={blog.title}
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-bold  mb-2"  style={{
          color: `${primaryColor} `,
          
       }}>{blog.title}</h3>
                <p className="text-sm   mb-4" style={{color:secondaryColor}}>{formatDateToIST(blog.createdAt)}</p>

                {/* Highlight category with a red badge */}
                <div className="inline-block    text-xs px-3 py-1 rounded-full mb-4" style={{backgroundColor:primaryColor, color:backgroundColor}}> 
                  {blog.category}
                </div>

                {/* Strip HTML tags and limit content to 100 characters */}
                <p className=" line-clamp-3"  style={{
          color: `${secondaryColor} `,
          
       }}>
                  {stripHtmlTags(blog.content).substring(0, 100)}...
                </p>
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default BlogList;
