import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast'; // Import react-hot-toast
import { useSelector } from 'react-redux';

const PrivacyPolicyShow = () => {
  const [privacyContent, setPrivacyContent] = useState('');
  const [error, setError] = useState('');
  const user = useSelector((state) => state?.user?.user);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    const fetchPrivacyContent = async () => {
      toast.loading('Fetching Privacy Policy...');

      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-privacy/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        setPrivacyContent(data?.privacy.content);
        toast.dismiss(); // Dismiss loading toast
      } catch (error) {
        setError(error.message);
        toast.dismiss(); // Dismiss loading toast
        toast.error('Error fetching Privacy Policy'); // Show error toast
      }
    };

    fetchPrivacyContent();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        console.error('Error fetching header color:', error);
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  return (
     <div  >
  <div className="max-w-4xl mx-auto p-6 rounded-lg " >
      <Toaster position="top-right" /> {/* Add Toaster to show toast notifications */}

      <h1 className="text-4xl font-bold text-center mb-6" style={{ color:  primaryColor }}>Privacy Policy</h1>

      <div className="privacy-content leading-relaxed" style={{ color: secondaryColor }}>
        {privacyContent ? (
          <div dangerouslySetInnerHTML={{ __html: privacyContent }} className="prose lg:prose-xl max-w-none" />
        ) : (
          <p className="text-center">No privacy policy content available.</p>
        )}
      </div>
    </div>
     </div>
  );
};

export default PrivacyPolicyShow;
