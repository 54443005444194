import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SummaryApi from '../common';
import VerticalCard from '../components/VerticalCard';

const SearchProduct = () => {
  const query = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]); // State to hold filtered product data

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.searchProduct.url + query.search);
      const dataResponse = await response.json();
      // console.log("hfgghfhg",dataResponse)
      setData(dataResponse.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [query]);

  useEffect(() => {
    const results = data.filter(product => product.owner === `${process.env.REACT_APP_USER_ID}`);
    setFilteredProducts(results);
  }, [data]); // Run filtering when products change

  return (
    <div className="container mx-auto p-4">
      {loading && (
        <div className="flex justify-center items-center min-h-screen">
          <div className="loader"></div> {/* Custom loader */}
        </div>
      )}

      {!loading && (
        <>
          <p className="text-lg font-semibold my-3">
            Search Results: {filteredProducts.length}
          </p>

          {filteredProducts.length === 0 ? (
            <div className="bg-white text-lg text-center p-4 border border-gray-300 rounded-lg shadow-md">
              <p>No Data Found....</p>
              <p className="mt-2 text-gray-500">Try adjusting your search criteria.</p>
            </div>
          ) : (
            <VerticalCard loading={loading} data={filteredProducts} />
          )}
        </>
      )}
    </div>
  );
};

export default SearchProduct;
