 
const { default: SummaryApi } = require("../common")

const fetchCategoryWiseProduct = async(category,owner)=>{
  
    const response = await fetch(SummaryApi.categoryWiseProduct.url,{
        method : SummaryApi.categoryWiseProduct.method,
        headers : {
            "content-type" : "application/json"
        },
        body : JSON.stringify({
            category : category,
            owner:owner
        })
    })

    const dataResponse = await response.json()
  console.log("dataresponse",dataResponse)
    return dataResponse
}

export default fetchCategoryWiseProduct