import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SummaryApi from "../common";
 
import CategroyWiseProductDisplay from "../components/CategoryWiseProductDisplay";
import { Helmet } from "react-helmet";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
 
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { FaShareAlt } from 'react-icons/fa';
const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    shortdescription: "",
  });
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [activeImage, setActiveImage] = useState("");
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
    x: 0,
    y: 0,
  });
  const [zoomImage, setZoomImage] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", mobile: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state?.user?.user);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [aboutData, setAboutData] = useState(null);
  const fetchProductDetails = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.productDetails.url, {
      method: SummaryApi.productDetails.method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ productId: params?.id }),
    });
    setLoading(false);
    const dataResponse = await response.json();
    setData(dataResponse?.data);
    setActiveImage(dataResponse?.data?.productImage[0]);
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);

  const handleMouseEnterProduct = (imageURL) => {
    console.log("imageUrl",imageURL)
    setActiveImage(imageURL);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title:  data.productName,
          brandName:data.brandName,
          text: `Check outs product: ${data.productName} by ${data.brandName}`,
                 url: window.location.href,
        });
        toast.success('Blog shared successfully!');
      } catch (error) {
        toast.error('Error sharing blog');
      }
    } else {
      toast.info('Sharing not supported in this browser');
    }
  };

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomImageCoordinate({ x, y });
  }, []);

  const handleLeaveImageZoom = () => {
    setZoomImage(false);
  };

  const handlePopupOpen = () => setIsPopupOpen(true);
  const handlePopupClose = () => setIsPopupOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function tosendWhatsappMessage() {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${process.env.REACT_APP_USER_ID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // throw new Error('Failed to fetch WhatsApp configuration.');
      }
      const data = await response.json();
      let phoneNumber = formData.mobile;
      if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.slice(-10); // Take the last 10 digits
      }
      phoneNumber = "91" + phoneNumber;

      const response1 = await fetch(
        `${data.data.api}/api/wpbox/sendtemplatemessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: data.data.token,
            phone: phoneNumber, // Use the mobile number from form data
            template_name: data.data.template_name,
            template_language: data.data.template_language,
          }),
        }
      );

      // console.log("whatsapp reesp", response1)
    } catch (error) {
      // toast.success('Error fetching WhatsApp configuration.');
    }
  }

  const highlights = data?.shortdescription?.split(",") || [];
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const emailData = {
      to: formData.email,
      subject: `Enquiry from ${formData.name}`,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
      html: `<p><strong>Name:</strong> ${formData.name}</p><p><strong>Mobile:</strong> ${formData.mobile}</p><p><strong>Email:</strong> ${formData.email}</p>`,
      product: data,
    };

    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailData),
        }
      );
      const data = await response.json();
      // console.log('Response Data:', data);
 
        toast.success("Email sent successfully!");
        setFormData({ name: "", email: "", mobile: "" });
        setIsPopupOpen(false);
        setIsSubmitting(false);
        tosendWhatsappMessage();
        setIsSubmitting(false);
    
    } catch (error) {
      // console.log("ttttttt",error)
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to extract number and text
  function extractNumberAndText(input) {
    const regex = /^(\d+)\s*(.*)$/;
    const match = input.match(regex);

    if (match) {
      const number = match[1];
      const text = match[2];
      return { number, text };
    }

    return null;
  }

  // Extract number from data.price
  const extractedData = extractNumberAndText(data.price);
  const extractedNumber = extractedData ? extractedData.number : data.price;
  const extractedText = extractedData ? extractedData.text : "";

  const data1 = {
    metaTitle: "Contact Us | Your Company",
    metaDescription: "Get in touch with us for any inquiries or support.",
    metaKeywords: "contact, support, inquiry, help",
  };

  // Fallback values for SEO
  const fallbackMetaTitle = data.productName;
  const fallbackMetaDescription = data.description;
  const fallbackMetaKeywords = data.description;
  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
        if (response.ok) {
          const data = await response.json();
          setAboutData(data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (err) {
        // setError(err.message);
      }  
    };
    fetchAboutData();
  }, [user?._id]);
  const displayINRCurrency1 = (num) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });

    return `Starting From: ${formatter.format(num)}`;
  };

  return (
    <>
      <Helmet>
  {/* Primary Meta Tags */}
  <title>{data?.metaTitle || fallbackMetaTitle}</title>
  <meta name="description" content={data?.metaDescription || fallbackMetaDescription} />
  <meta name="keywords" content={data?.metaKeywords || fallbackMetaKeywords} />
  
  {/* Open Graph / Facebook Meta Tags */}
  <meta property="og:type" content="product" />
  <meta property="og:title" content={data?.metaTitle || fallbackMetaTitle} />
  <meta property="og:description" content={data?.metaDescription || fallbackMetaDescription} />
  <meta property="og:image" content={`https://builder.biztoindia.in/${activeImage}`} />
  <meta property="og:url" content={window.location.href} />
  {/* <meta property="og:site_name" content="Your Company" /> */}

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={data?.metaTitle || fallbackMetaTitle} />
  <meta name="twitter:description" content={data?.metaDescription || fallbackMetaDescription} />
  <meta name="twitter:image" content={`https://builder.biztoindia.in/${activeImage}`} />
  
  {/* Structured Data for Products (JSON-LD) */}
  {/* <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Product",
      name: data?.productName,
      image: data?.productImage.map((img) => `https://builder.biztoindia.in/${img}`),
      description: data?.description,
      brand: data?.brandName,
      
    })}
  </script> */}

  {/* Additional SEO Tags */}
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href={window.location.href} />
  <meta name="author" content="BizToIndia" />
  <meta httpEquiv="content-language" content="en" />
</Helmet>

      <div className="container mx-auto p-4  font-lub" style={{backgroundColor:backgroundColor}}>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="min-h-[200px] flex flex-col lg:flex-row gap-4">
          <div className="h-96 flex flex-col lg:flex-row-reverse gap-4">
            <div className="h-[300px] w-[300px] lg:h-96 lg:w-96   relative p-2">
              <img
                src={`https://builder.biztoindia.in/${activeImage}`}
                className="h-full w-full object-scale-down mix-blend-multiply"
                onMouseMove={handleZoomImage}
                onMouseLeave={handleLeaveImageZoom}
              />
             

            </div> 

            <div className="h-full">
              {/* Product Image Thumbnails */}
              <div className="flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full">
                {loading ? (
                  <div className="animate-pulse">Loading...</div>
                ) : (
                  data?.productImage?.map((imgURL) => (
                    <div
                      className="h-20 w-20 bg-slate-200   rounded p-1 ml-0 lg:ml-[100px]"
                      key={imgURL}
                    >
                      
                      <img
                          src={`https://builder.biztoindia.in/${imgURL}`}
                        className="w-full h-full object-scale-down mix-blend-multiply cursor-pointer"
                        onMouseEnter={() => handleMouseEnterProduct(imgURL)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {loading ? (
            <div>Loading Product Details...</div>
          ) : (
            <div className="flex flex-col  gap-1 ml-0 lg:ml-[200px]">
              <p className="  px-2 rounded-full inline-block w-fit" style={{color:backgroundColor, backgroundColor:primaryColor}}>
                {data?.brandName}
              </p>
              <h1 className="text-2xl lg:text-4xl font-medium" style={{color:primaryColor}}>
                {data?.productName}
              </h1>
              <h2 className="capitalize" style={{color:secondaryColor}}>{data?.category}</h2>

              { data?.isPriceVisible &&  (<div className="flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1">
                <p style={{color:secondaryColor}}>
                  <span
                    className=" font-bold"
                    style={{
                      color: `${secondaryColor} `,
                    }}
                  >
                    {displayINRCurrency1(extractedNumber)}
                  </span>
                  {/* <span className="text-gray-600"><span className='text-bold'>/</span> {extractedText}</span> */}
                </p>
              </div> )}
           
            <div className="flex justify-start gap-x-3">
               <button
                onClick={handlePopupOpen}
                className=" hover:bg-blue-600 transition-all text-white   mt-3  w-60 rounded-full"
                style={{
                  backgroundColor: `${primaryColor} `,
                  color:`${backgroundColor}`,
                }}
              >
                Enquiry Now
              </button>
              
              <div >
                
              <a
  href={`https://wa.me/${aboutData?.contactNumber}?text= Hello, I would like to enquire about  : ${data.productName} - ${window.location.href}`}
  target="_blank"
  rel="noopener noreferrer"
  className="flex items-center justify-center bg-green-500 text-white    rounded-full shadow-lg hover:bg-green-600 transition duration-300 mt-4 w-fit"
  aria-label="WhatsApp"
>
<AiOutlineWhatsApp size={30}   />
 
 </a>
 
              </div>
             

            </div>
             

              <div></div>
              <h2 className=" font-medium my-1" style={{color:secondaryColor}}>
        Short Description:{" "}
      </h2>
     
      <ul className="list-none"> {/* Remove default list styles */}
  {highlights.slice(0, 4).map((highlight, index) => (
    <li
      key={index}
      className="flex items-center p-2 my-1 rounded-md shadow-sm"
    >
      <span className="w-2 h-2  rounded-full mr-2" style={{backgroundColor: secondaryColor}}></span> {/* Custom bullet */}
      <span className=" font-medium" style={{color: primaryColor}}>{highlight.trim()}</span>
    </li>
  ))}
  
  <button
                onClick={handleShare}
                className="flex items-center p-2 ml-2 rounded-md transition"
                style={{ color:  backgroundColor, backgroundColor:  primaryColor }}
              >
                <FaShareAlt  className="mr-2" />
                Share
              </button>
</ul>

   

              {/* Popup Form */}
        
              {isPopupOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 sm:px-6">
    <div className="bg-white rounded-xl shadow-lg p-8 max-w-lg w-full relative sm:mx-auto sm:my-6">
      <button
        onClick={handlePopupClose}
        className="absolute top-2 right-2 text-2xl font-bold text-gray-600 hover:text-gray-800 transition-colors"
      >
        &times;
      </button>
      <h2 className="text-xl font-semibold mb-6 text-center text-gray-800">
        Enquiry Form
      </h2>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div>
          
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
            placeholder="Enter your name"
            required
          />
        </div>

        <div>
           
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
            placeholder="Enter your email"
            required
          />
        </div>

        <div>
           
          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
            placeholder="Enter your mobile number"
            required
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold px-6 py-3 rounded-full shadow-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 ease-in-out transform hover:scale-105"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Enquiry"}
          </button>
        </div>
      </form>
    </div>
  </div>
)}

            </div>
          )}
        </div>

        <div className="my-4">
          <h2 className="text-xl font-semibold  mb-2" style={{color:primaryColor}}>
            Description:
          </h2>
          <div
            className=" leading-relaxed text-justify bg-gray-50 p-4 rounded-lg shadow-md"
            style={{
              color: `${secondaryColor} `,
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        </div>

        <CategroyWiseProductDisplay
          category={data?.category}
          owner={`${process.env.REACT_APP_USER_ID}`}
        />
      </div>
    </>
  );
};

export default ProductDetails;
