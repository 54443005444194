import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const ContactUs = () => {
  const user = useSelector(state => state?.user?.user);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    phone: '',
    email: '',
    officeAddress: {
      city: '',
      pinCode: '',
      state: '',
      sector: '',
      gstMsme:'',
      address:'',
    },
    registeredAddress: {
      city: '',
      pinCode: '',
      state: '',
      sector: '',
      gstMsme:'',
      address:'',
    },
    googleMapLink: ''
  });
  const [error, setError] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [aboutData, setAboutData] = useState(null);
  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
        if (response.ok) {
          const data = await response.json();
          setAboutData(data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (err) {
        setError(err.message);
      }  
    };
    fetchAboutData();
  }, [user?._id]);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);


  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  async function tosendWhatsappMessage() {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${process.env.REACT_APP_USER_ID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // throw new Error('Failed to fetch WhatsApp configuration.');
      }
      const data = await response.json();
      let phoneNumber = formData.mobile;
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(-10); // Take the last 10 digits
      }
      phoneNumber = "91" + phoneNumber;

      const response1 = await fetch(
        `${data.data.api}/api/wpbox/sendtemplatemessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: data.data.token,
            phone: phoneNumber, // Use the mobile number from form data
            template_name: data.data.template_name,
            template_language: data.data.template_language,
          }),
        }
      );

      // console.log("whatsapp reesp", response1)
    } catch (error) {
      // toast.success('Error fetching WhatsApp configuration.');
    }
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const emailData = {
      to: formData.email,
      subject: `Message from ${formData.name}`,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
      html: `
        <p><strong>Name:</strong> ${formData.name}</p>
        <p><strong>Mobile:</strong> ${formData.mobile}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Message:</strong> ${formData.message}</p>
      `,
    };

    try {
      const response = await fetch(`https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
 
        toast.success('Email sent successfully!');
        setFormData({ name: '', email: '', mobile: '', message: '' });
      
      
      setIsSubmitting(false);
      tosendWhatsappMessage();
    }catch(err){
      
    }
  };

  // Fetch contact details
  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-contact/${process.env.REACT_APP_USER_ID}`);
        if (!response.ok) {
          throw new Error('Error fetching contact details.');
        }
        const data = await response.json();
        setContactDetails({
          phone: data.data.phone || '',
          email: data.data.email || '',
          officeAddress: data.data.officeAddress || {},
          registeredAddress: data.data.registeredAddress || {},
          googleMapLink: data.data.googleMapIframe || ''
        });
      } catch (err) {
        // setError(err.message);
        // toast.error(err.message);
      }
    };

    fetchContactDetails();
  }, [ `${process.env.REACT_APP_USER_ID}`]);

  return (
    <>
       <Helmet>
 
  <title>{`Contact Us | ${aboutData?.companyName || 'Our Website'}`}</title>
  <meta name="description" content="Get in touch with us . We're here to assist you with any inquiries or support." />
  <link rel="canonical" href={window.location.href} />

  {/* General SEO Tags */}
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta charSet="utf-8" />
  <meta name="robots" content="index, follow" />
  <meta name="keywords" content="contact us, customer support, inquiries, feedback, help" />
  <meta name="author" content={aboutData?.companyName} />

  {/* Open Graph Tags for Facebook & LinkedIn */}
  <meta property="og:title" content="Contact Us | BizToIndia" />
  <meta property="og:description" content="Reach out to Us for inquiries, support, or feedback. We're here to help." />
  <meta property="og:url" content={window.location.href} />
  
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content={aboutData?.companyName} />
  
  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={`Contact Us | ${aboutData?.companyName}`} />
  <meta name="twitter:description" content={`Get in touch with us at ${aboutData?.companyName} for any inquiries or support.`}/>
  {/* <meta name="twitter:image" content="https://builder.biztoindia.in/assets/contact-page-banner.jpg" /> Replace with actual image */}
 

  {/* Structured Data (JSON-LD) */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "name": `Contact Us | ${aboutData?.companyName}`,
      "description": `Reach out to ${aboutData?.companyName} for inquiries, support, or feedback. We're here to help.`,
      "url": window.location.href,
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": `${aboutData?.contactNumber}`, // Replace with actual phone number
        "contactType": "Customer Service",
        "areaServed": "Worldwide",
        "availableLanguage": ["English"]
      }
    })}
  </script>
</Helmet>


      <section className=" py-12 px-4 sm:px-6 lg:px-8  font-lub" style={{backgroundColor:backgroundColor}}>
        <div className="max-w-7xl mx-auto text-center mb-12">
          <h1 className="text-4xl font-extrabold "  style={{
          color: `${ primaryColor} `,
          
       }}>Contact Us</h1>
          <p className="mt-4 text-lg " 

style={{
         color: `${secondaryColor} `,
         
      }}>
            We’d love to hear from you! Whether you’re curious about products or just need assistance, we’re here to help.
          </p>
        </div>

        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-8 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
            <FaPhoneAlt className="text-4xl  mb-4" 

style={{
         color: `${ primaryColor} `,
         
      }}  />
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Phone</h3>
            <p className=" mb-4" style={{
         color: `${secondaryColor} `,
         
      }}>Call us at any time</p>
            <a href={`tel:${contactDetails.phone}`} className="text-lg text-gray-800 font-semibold">
              {contactDetails.phone}
            </a>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
            <FaEnvelope className="text-4xl  mb-4" 

style={{
         color: `${ primaryColor} `,
         
      }} />
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Email</h3>
            <p className=" mb-4" style={{
         color: `${secondaryColor} `,
         
      }}>Reach out via email</p>
            <a href={`mailto:${contactDetails.email}`} className="text-lg text-gray-800 font-semibold">
              {contactDetails.email}
            </a>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
          {contactDetails?.officeAddress?.city && (
    <>
        <FaMapMarkerAlt className="text-4xl  mb-4" 

style={{
         color: `${ primaryColor} `,
         
      }} />
        <h3 className="text-2xl font-semibold  mb-2"  >Office Address</h3>
        <p className="text-lg  font-semibold" style={{
         color: `${secondaryColor} `,
         
      }}>
            {`${contactDetails?.officeAddress?.address}`}
        </p>
        <p className="text-lg font-semibold" style={{
         color: `${secondaryColor} `,
         
      }}>
            {`${contactDetails?.officeAddress?.sector}, ${contactDetails?.officeAddress?.city}, ${contactDetails?.officeAddress?.state} - ${contactDetails?.officeAddress?.pinCode}`}
        </p>
        <p className="text-lg  font-semibold" style={{
         color: `${secondaryColor} `,
         
      }}>
            {`${contactDetails?.officeAddress?.gstMsme || ''}`}
        </p>
    </>
)}

{contactDetails?.registeredAddress.city && (
    <>
        <FaMapMarkerAlt className="text-4xl  mb-4 mt-4" 

style={{
         color: `${ primaryColor} `,
         
      }} />
        <h3 className="text-2xl font-semibold   mb-2 mt-4" style={{
         color: `${secondaryColor} `,
         
      }}>Registered Address</h3>
        <p className="text-lg font-semibold" style={{
         color: `${secondaryColor} `,
         
      }}>
            {`${contactDetails?.registeredAddress?.address}`}
        </p>
        <p className="text-lg  font-semibold" style={{
         color: `${secondaryColor} `,
         
      }}>
            {`${contactDetails?.registeredAddress?.sector}, ${contactDetails?.registeredAddress?.city}, ${contactDetails?.registeredAddress?.state} - ${contactDetails?.registeredAddress?.pinCode}`}
        </p>
        <p className="text-lg  font-semibold" style={{
         color: `${secondaryColor} `,
         
      }}>
            {`${contactDetails?.registeredAddress?.gstMsme || ''}`}
        </p>
    </>
)}

               
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Send Us a Message</h3>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Full Name</label>
                <input
                  type="text"
                  name="name"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-blue-600"
                  placeholder="Your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-blue-600"
                  placeholder="you@example.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-blue-600"
                  placeholder="Your mobile number"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Message</label>
                <textarea
                  rows="4"
                  name="message"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:blue-red-600"
                  placeholder="Write your message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-3 text-white  rounded-lg hover:bg-blue-700 transition-all"
                

 style={{
   backgroundColor: `${primaryColor} `,
  
}}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>

       


        <Toaster />
      </section>
      <div className="mt-10 flex justify-center mb-10" >
  {contactDetails.googleMapLink && 
    contactDetails.googleMapLink.startsWith('<iframe') && 
    contactDetails.googleMapLink.endsWith('</iframe>') ? (
      <div dangerouslySetInnerHTML={{ __html: contactDetails.googleMapLink }} />
    ) : null
  }
</div>
    </>
  );
};

export default ContactUs;
