import { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ViewMoreClients = () => {
  const [allImages, setAllImages] = useState([]);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [aboutData, setAboutData] = useState(null);

 

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
        }
      } catch (error) {
        console.error('Error fetching header colors:', error);
      }
    };
    const fetchAboutData = async () => {
        try {
          const response = await fetch(
            `https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`
          );
          const data = await response.json();
          setAboutData(data);
        } catch (error) {
          // console.error("Error fetching About Us data:", error);
        }
      };
  
      
       
       
  
      fetchAboutData();
    fetchHeaderColor();
  }, []);

  useEffect(() => {
    const fetchAllImages = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/clients?owner=${process.env.REACT_APP_USER_ID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Error fetching images');
        }

        const data = await response.json();
        setAllImages(data);
      } catch (error) {
        console.error('Error fetching all images:', error);
        toast.error('Error fetching images.');
      }
    };

    fetchAllImages();
  }, []);

  return (
    <>
      {/* SEO Elements */}
      <Helmet>
        <title>Our Clients |  {`${aboutData?.companyName}`}</title>
        <meta name="description" content="Explore more of our esteemed clients and see how we collaborate with industry leaders." />
        <meta name="keywords" content="clients, partnerships, BizToIndia, client logos, industry leaders" />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="max-w-7xl mx-auto p-8 font-lub rounded-lg">
        <Toaster />
        <h2 className="text-4xl font-extrabold text-center mb-10" style={{ color: primaryColor }}>All Clients</h2>
        <p className="text-center text-lg mb-8" style={{ color: secondaryColor }}>
          Here are all of our valued partners that we proudly collaborate with.
        </p>

        {/* Display all client logos in a responsive flexbox layout */}
        <div className="flex flex-wrap justify-center gap-4">
          {allImages.map((client, index) =>
            client.imageUrls.map((url, i) => (
              <div
                key={`${index}-${i}`}
                className="flex items-center justify-center p-4 rounded-md shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300"
                style={{ width: '20%', minWidth: '150px', height: '150px' }}
              >
                <img
                  src={`https://builder.biztoindia.in/${url}`}
                  alt={`Client ${i}`}
                  className="object-contain w-full h-full rounded-md"
                />
              </div>
            ))
          )}
        </div>

        {/* Back to Our Clients Button */}
       
      </div>
    </>
  );
};

export default ViewMoreClients;
