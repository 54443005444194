import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
 
 
 

 
 
import CategoryProduct from '../pages/CategoryProduct'
import ProductDetails from '../pages/ProductDetails'
import Cart from '../pages/Cart'
import SearchProduct from '../pages/SearchProduct'
import AboutUs from '../pages/AboutUs'
import ContactUs from '../pages/ContactUs'
import ShowAllProducts from '../pages/ShowAllProducts'
 
import ShowGallery from '../components/ShowGallery'
 
import BlogList from '../components/BlogList'
import BlogDetails from '../pages/BlogDetails'
 
import FaqShow from '../components/FaqShow'
 
import PrivacyPolicyShow from '../components/PrivacyPolicyShow'
 
import ViewMoreClients from '../pages/AllCients'
import TestimonialsUi from '../pages/Testimonials'
import ShippingPolicyShow from '../components/Shippingandreturnpolicy'
import RefundPolicyShow from '../components/refundpolicy'
import TermsOfUseShow from '../components/TermOfUse'
import Sitemap from '../components/Sitemap'
const router = createBrowserRouter([
    {
        path : "/",
        element : <App/>,
        children : [
            {
                path : "",
                element : <Home/>
            },
           
            {
                path : "privacy-policy",
                element : <PrivacyPolicyShow/>
            },
            {
                path : "login",
                element : <Home/>
            },
            
            {
                path : "faq",
                element : <FaqShow/>
            },
            {
                path : "product-category",
                element : <CategoryProduct/>
            },
            {
                path : "product/:id",
                element : <ProductDetails/>
            },
            {
                path:"blogs/:id",
                element:<BlogDetails/>
            },
             {
                path:"termsofuse",
                element:<TermsOfUseShow/>
            },
            {
                path : 'cart',
                element : <Cart/>
            },
            {
                path : 'Gallery',
                element : <ShowGallery/>
            },
            {
                path : "search",
                element : <SearchProduct/>
            },
            {
                path:"about",
                element:<AboutUs/>
            },
            {path:"blogs",
                element:<BlogList/>
            },
            {
                path:"contact",
                element:<ContactUs/>
            },
            {
                path:"all-products",
                element:<ShowAllProducts/>
            },
            {
                path:"allclients",
                element:<ViewMoreClients/>
            },
            {
                path:"testimonials",
                element:<TestimonialsUi/>
            },
            {
                path:"shippingandretunpolicy",
                element:<ShippingPolicyShow/>
            },
            {
                path:"refundpolicy",
                element:<RefundPolicyShow/>
            },
            {
                path:"sitemap",
                element:<Sitemap/>
            }
          
        ]
    }
])


export default router