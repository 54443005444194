import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const CategoryList = ({ backgroundColor ,primaryColor,secondaryColor}) => {
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const categoryLoading = new Array(13).fill(null);
  const scrollContainerRef = React.useRef(null);

  const fetchCategoryProduct = async () => {
    setLoading(true);
    const response = await fetch(`${SummaryApi.allcategory.url}/${process.env.REACT_APP_USER_ID}`);
    const dataResponse = await response.json();
    setLoading(false);
    setCategoryProduct(dataResponse?.categories);
  };

  useEffect(() => {
    fetchCategoryProduct();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -scrollContainerRef.current.offsetWidth / 2, // Scroll left by half the container width
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: scrollContainerRef.current.offsetWidth / 2, // Scroll right by half the container width
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='container mx-auto p-4' style={{ backgroundColor }}>
    <div className='relative'>
      <button
        onClick={scrollLeft}
        className="absolute left-0 -ml-8 top-1/2 transform -translate-y-1/2 p-2   z-10"
        style={{color:  primaryColor}}
      >
        <FaChevronLeft />
      </button>
      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2   z-10 -mr-8"
        style={{color:primaryColor}}
        
      >
        <FaChevronRight />
      </button>
  
      <div
        ref={scrollContainerRef}
        className='flex items-center gap-4 justify-evenly overflow-scroll scrollbar-none'
      >
        {loading ? (
          categoryLoading?.map((_, index) => (
            <div
              className='h-20 w-20 md:w-28 md:h-28 lg:w-32 lg:h-32 rounded-full overflow-hidden bg-slate-200 animate-pulse'
              key={"categoryLoading" + index}
            ></div>
          ))
        ) : (
          categoryProduct?.map((product) => (
            <Link
              to={'/product-category?category=' + product?.label}
              className='group cursor-pointer flex flex-col items-center space-y-2 sm:space-y-3'
              key={product?.label}
            >
              <div className='w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 rounded-full overflow-hidden p-2 sm:p-4 bg-slate-200 flex items-center justify-center shadow-lg transition-transform duration-300 transform group-hover:scale-105'>
                <img
                  src={`https://builder.biztoindia.in/${product?.image}`}
                  alt={product?.label}
                  className='h-full object-scale-down mix-blend-multiply transition-transform duration-300 group-hover:scale-125'
                />
              </div>
              <p className='text-center text-xs sm:text-sm md:text-base capitalize font-medium group-hover:text-blue-500 transition-colors line-clamp-2' style={{color:primaryColor}}>
                {product?.label}
              </p>
            </Link>
          ))
        )}
      </div>
    </div>
  </div>
  
  );
};

export default CategoryList;
