import React, { useState, useEffect } from 'react';
import { FaQuoteLeft, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { toast } from 'react-toastify';

const TestimonialsUi1 = ( ) => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [primaryColor,setPrimaryColor]=useState('');

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {}
    };
    fetchHeaderColor();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/items/${process.env.REACT_APP_USER_ID}`);
      if (response.ok) {
        const data = await response.json();
        setTestimonials(data);
      } else {
        throw new Error('Error fetching testimonials');
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const isMobile = window.innerWidth < 768;
  const slidesToShow = isMobile ? 1 : 2;

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonials.length);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1));
  };

  const limitDescription = (description) => {
    return description.length > 250 ? `${description.slice(0, 250)}...` : description;
  };

  return (
    <div className="py-16  font-lub " style={{ backgroundColor }}>
      <div className="container mx-auto px-4 md:px-12">
        {testimonials?.length > 0 && (
          <h2 className="text-center text-3xl md:text-4xl font-bold mb-8 md:mb-12" style={{ color: primaryColor }}>
            What Our Clients Say
          </h2>
        )}
        <div className="relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-700 ease-in-out"
              style={{
                transform: `translateX(-${(currentSlide * 100) / slidesToShow}%)`,
                width: `${(testimonials.length / slidesToShow) * 100}%`,
              }}
            >
              {testimonials.map((testimonial) => (
                <div key={testimonial._id} className="w-full md:w-1/2 px-4 mb-8">
                  <div className= "bg-white rounded-lg shadow-lg p-6 md:p-8 flex items-center transition-shadow duration-300 hover:shadow-2xl">
                    <div className="w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 mr-4 md:mr-6">
                      <img
                        src={`https://builder.biztoindia.in/${testimonial?.image}`}
                        alt={testimonial.name}
                        className="rounded-full object-cover w-full h-full shadow-md"
                      />
                    </div>
                    <div className="flex-1">
                      <FaQuoteLeft className="text-2xl md:text-3xl mb-4" style={{ color: primaryColor }} />
                      <p className="text-sm md:text-md mb-4 italic" style={{ color: secondaryColor }}>
                        "{limitDescription(testimonial.description)}"
                      </p>
                      <h3 className="text-lg font-semibold" style={{ color: primaryColor }}>
                        {testimonial.name}
                      </h3>
                      <p style={{ color: secondaryColor }}>{testimonial.designation}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Arrows */}
          {testimonials.length > 1 && (
            <div className="absolute inset-0 flex justify-between items-center px-4 md:px-6">
              <button
                className="p-3 rounded-full hover:shadow-lg transition-all transform hover:scale-110"
                style={{ backgroundColor: primaryColor, color: secondaryColor }}
                onClick={goToPrevSlide}
              >
                <FaArrowLeft />
              </button>
              <button
                className="p-3 rounded-full hover:shadow-lg transition-all transform hover:scale-110"
                style={{ backgroundColor: primaryColor, color: secondaryColor }}
                onClick={goToNextSlide}
              >
                <FaArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsUi1;
