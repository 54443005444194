import React, { useState, useEffect } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import maintainece from '../assest/comming.jpg';
import { useNavigate } from 'react-router-dom';

const HomeGallery = () => {
  const [files, setFiles] = useState([]);
  const [aboutData, setAboutData] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const navigate = useNavigate();

  const fetchAboutData = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
      const data = await response.json();
      setAboutData(data);
    } catch (error) {
      console.error('Error fetching about data:', error);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/files/${process.env.REACT_APP_USER_ID}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setFiles(data);
      } else {
        setFiles([]);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    fetchFiles();
    fetchAboutData();
  }, []);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        console.error('Error fetching header color:', error);
      }
    };
    fetchHeaderColor();
  }, []);

  const isYouTubeUrl = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  };

  const getYouTubeEmbedUrl = (url) => {
    const regExp = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? `https://www.youtube.com/embed/${match[1]}` : url;
  };

  const renderFile = (file) => {
    const fileType = file.mimeType?.split('/')[0];

    if (file.url && isYouTubeUrl(file.url)) {
      return (
        <iframe
          width="100%"
          height="250"
          src={getYouTubeEmbedUrl(file.url)}
          title={file.originalName}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="rounded-lg shadow-lg"
        />
      );
    }

    switch (fileType) {
      case 'image':
        return (
          <Zoom>
            <img
              src={`https://builder.biztoindia.in/${file.path}`}
              alt={file.originalName}
              title={file.originalName}
              className="w-full h-48 sm:h-64 object-contain rounded-lg border border-gray-300 shadow-lg transition-transform duration-300 hover:scale-105"
            />
          </Zoom>
        );
      case 'video':
        return (
          <video
            controls
            className="w-full h-48 sm:h-64 object-cover rounded-lg border border-gray-300 shadow-lg transition-transform duration-300 hover:scale-105"
          >
            <source src={`https://builder.biztoindia.in/${file.path}`} type={file.mimeType} />
            Your browser does not support the video tag.
          </video>
        );
      case 'audio':
        return (
          <audio
            controls
            className="w-full h-48 sm:h-64 rounded-lg border border-gray-300 shadow-lg bg-gray-100 transition-transform duration-300 hover:scale-105"
          >
            <source src={`https://builder.biztoindia.in/${file.path}`} type={file.mimeType} />
            Your browser does not support the audio element.
          </audio>
        );
      case 'application':
        return (
          <a
            href={`https://builder.biztoindia.in/${file.path}`}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full h-48 sm:h-64 flex items-center justify-center rounded-lg border border-gray-300 shadow-lg bg-gray-200 text-gray-700 hover:bg-gray-300 transition-transform duration-300 hover:scale-105"
          >
            <p className="text-center">View Document</p>
          </a>
        );
      default:
        return (
          <div className="w-full h-48 sm:h-64 flex items-center justify-center rounded-lg border border-gray-300 shadow-lg bg-gray-200 text-gray-700">
            <p>Unsupported file type</p>
          </div>
        );
    }
  };

  if (files.length === 0) {
    return null;
  }

  const displayedFiles = files.slice(0, 10);

  return (
    <div className="flex font-lub  flex-col items-center justify-center min-h-screen bg-gray-100 p-4 sm:p-8 lg:p-16" style={{ backgroundColor }}>
      <h1 className="text-3xl font-bold mb-4 text-center mt-3" style={{ color: primaryColor }}>Gallery</h1>

      {files.length === 0 ? (
        <div className="text-center text-gray-600 py-10 px-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
          <h1 className="text-2xl font-bold mb-4">We Are Updating Our Gallery</h1>
          <p className="mb-4">We are currently updating our media collection. Please check back soon for new images and videos!</p>
          <img src={maintainece} alt="Gallery Under Maintenance" className="w-full h-auto rounded-lg shadow-md" />
        </div>
      ) : (
        <>
          <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full`}>
            {displayedFiles.map((file, index) => (
              <div key={index} className="relative transition-transform duration-300 hover:scale-105">
                {renderFile(file)}
              </div>
            ))}
          </div>

          {files.length > 10 && (
            <button
              className="mt-6 px-6 py-2 bg-primaryColor text-white font-semibold rounded-lg hover:bg-opacity-90 transition-all duration-300"
              onClick={() => navigate('/Gallery')}
              style={{ backgroundColor: primaryColor }}
            >
              View More
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default HomeGallery;
