import React, { useContext, useEffect, useRef, useState } from "react";
import fetchCategoryWiseProduct from "../helpers/fetchCategoryWiseProduct";
import displayINRCurrency from "../helpers/displayCurrency";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import addToCart from "../helpers/addToCart";
import Context from "../context";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
const VerticalCardProduct1 = ({ category, heading }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadingList = new Array(13).fill(null);

  const [scroll, setScroll] = useState(0);
  const scrollElement = useRef();

  const { fetchUserAddToCart } = useContext(Context);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const user = useSelector((state) => state?.user?.user);

  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id);
    fetchUserAddToCart();
  };

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const fetchData = async () => {
    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(
      category,
      `${process.env.REACT_APP_USER_ID}`
    );
    setLoading(false);

    // console.log('vertical data', categoryProduct.data);
    setData(categoryProduct?.data);
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  const scrollRight = () => {
    scrollElement.current.scrollLeft += 300;
  };
  const scrollLeft = () => {
    scrollElement.current.scrollLeft -= 300;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function tosendWhatsappMessage() {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${process.env.REACT_APP_USER_ID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // throw new Error('Failed to fetch WhatsApp configuration.');
      }
      const data = await response.json();
      let phoneNumber = formData.mobile;
    if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(-10); // Take the last 10 digits
    }
    phoneNumber = "91" + phoneNumber;

      const response1 = await fetch(
        `${data.data.api}/api/wpbox/sendtemplatemessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: data.data.token,
            phone: phoneNumber, // Use the mobile number from form data
            template_name: data.data.template_name,
            template_language: data.data.template_language,
          }),
        }
      );

      // console.log("whatsapp reesp", response1)
    } catch (error) {
      // toast.success('Error fetching WhatsApp configuration.');
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const emailData = {
      to: formData.email,
      subject: "Enquiry from " + formData.name,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
      html: `<p><strong>Name:</strong> ${formData.name}</p><p><strong>Mobile:</strong> ${formData.mobile}</p><p><strong>Email:</strong> ${formData.email}</p>`,
      product: selectedProduct,
    };

    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );

      toast.success("Email sent successfully!");
      setFormData({ name: "", email: "", mobile: "" });
      setIsPopupOpen(false);
      setIsSubmitting(false);
      tosendWhatsappMessage();
    } catch (error) {
      // toast.error('Error sending email.');
    }  
  };
  const displayINRCurrency1 = (num) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });

    return `Starting From: ${formatter.format(num)}`;
  };

  return (
    <div className="container mx-auto px-4 my-6  font-lub relative   " style={{backgroundColor:backgroundColor}}>
      {/* Toaster for notifications */}
      <Toaster position="top-right" reverseOrder={false} />

      <h1 className="text-2xl font-semibold text-center py-4" style={{color:primaryColor}}>{heading}</h1>

      <div
        className="flex items-center gap-4 md:gap-6 overflow-x-scroll scrollbar-none transition-all"
        ref={scrollElement}
      >
        <button
          className="bg-white shadow-md rounded-full p-1 absolute left-0 text-lg hidden md:block"
          onClick={scrollLeft}
        >
          <FaAngleLeft />
        </button>
        <button
          className="bg-white shadow-md rounded-full p-1 absolute right-0 text-lg hidden md:block"
          onClick={scrollRight}
        >
          <FaAngleRight />
        </button>

        {loading
          ? loadingList.map((product, index) => {
              return (
                <div
                  key={index}
                  className="w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow"
                >
                  <div className="  h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse"></div>
                  <div className="p-4 grid gap-3">
                    <h2 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200"></h2>
                    <p className="capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2"></p>
                    <div className="flex gap-3">
                      <p className="text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2"></p>
                      <p className="text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2"></p>
                    </div>
                    <button className="text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse"></button>
                  </div>
                </div>
              );
            })
          : data.map((product, index) => {
              
              function extractNumberAndText(input) {
                const regex = /^(\d+)\s*(.*)$/;
                const match = input.match(regex);

                if (match) {
                  const number = match[1];
                  const text = match[2];
                  return { number, text };
                }

                return null;
              }

              // Extract number from data.price
              const extractedData = extractNumberAndText(product.price);
              const extractedNumber = extractedData
                ? extractedData.number
                : data.price;
              const extractedText = extractedData ? extractedData.text : "";
              return (
                <div
                  key={product?.slug}
                  className="w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px]  rounded-sm shadow"
                  style={{
                    color: `${backgroundColor} `,
                  }}
                >
                  <Link to={"/product/" + product?.slug}>
                    <div className="bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center">
                      <img
                        src={`https://builder.biztoindia.in/${product.productImage[0]}`}
                        alt={product?.productName}
                        title={product?.productName}
                        className="object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply"
                      />
                    </div>
                  </Link>
                  <div className="p-4 grid gap-3">
                    <Link to={"/product/" + product?.slug}>
                      <h2 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1 " style={{color:primaryColor}}>
                        {product?.productName}
                      </h2>
                      <p className="capitalize" style={{color:secondaryColor}}>
                        {product?.category}
                      </p>
                    </Link>
                    {product?.isPriceVisible && (
                      <div className="flex gap-3">
                        <p className="text-gray-800">
                          <span
                            className="font-bold"
                            style={{
                              color: `${secondaryColor} `,
                            }}
                          >
                            {displayINRCurrency1(extractedNumber)}
                          </span>
                          <span style={{color:secondaryColor}}>{extractedText}</span>
                        </p>
                      </div>
                    )}

                    <div
                      onClick={(e) => e.preventDefault() && e.stopPropagation()}
                    >
                      <button
                        onClick={() => {
                          setIsPopupOpen(true);
                          setSelectedProduct(product);
                        }}
                        className=" hover:bg-blue-600 transition-all duration-300 ease-in-out  py-2 px-4 rounded-full"
                        style={{
                          backgroundColor: `${primaryColor} `,
                          color:`${backgroundColor}`,
                        }}
                      >
                        Enquiry Now
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>

      {/* Modal */}
      {isPopupOpen && selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full relative">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-all"
              onClick={() => setIsPopupOpen(false)}
            >
              &times;
            </button>
            <h3 className="text-md text-left font-bold text-black mb-4">
              Get More Details on Email/WhatsApp
            </h3>

            {/* Form */}
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="mb-1">
                <input
                  type="text"
                  name="name"
                  placeholder="Name*"
                  className="form-input block w-full border-b-2 border-gray-300 bg-gray-100 py-2 px-4 focus:outline-none focus:border-black transition-all"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  className="form-input block w-full border-b-2 border-gray-300 bg-gray-100 py-2 px-4 focus:outline-none focus:border-black transition-all"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="tel"
                  name="mobile"
                  placeholder="Mobile*"
                  className="form-input block w-full border-b-2 border-gray-300 bg-gray-100 py-2 px-4 focus:outline-none focus:border-black transition-all"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-white border border-black hover:bg-black hover:text-white hover:border-red transition-all duration-300 ease-in-out text-black py-2 px-6 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 disabled:opacity-50 w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerticalCardProduct1;
