import React, { useEffect, useState } from "react";
import SummaryApi from "../common";
import { Link } from "react-router-dom";
import displayINRCurrency from "../helpers/displayCurrency";
import ContactForm from "../components/Enquiry";
import { Helmet } from "react-helmet";
import CategoryList from "../components/CategoryList";
import { useSelector } from "react-redux";
import maintenanceImage from"../assest/comming.jpg"; // Corrected path to 'assets'

const ShowAllProducts = () => {
  const [allProduct, setAllProduct] = useState([]);
  const [contactDetails, setContactDetails] = useState({});
  const user = useSelector((state) => state?.user?.user);
  const [aboutData, setAboutData] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  const fetchAllProduct = async () => {
    const response = await fetch(
      `${SummaryApi.allProduct.url}/${process.env.REACT_APP_USER_ID}`
    );
    const dataResponse = await response.json();
    
    setAllProduct(dataResponse?.data || []);
  };

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        console.error('Error fetching header colors:', error);
      }
    };

    fetchHeaderColor();
  }, []);

  const fetchContactDetails = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/get-contact/${process.env.REACT_APP_USER_ID}`);
      if (!response.ok) {
        throw new Error('Error fetching contact details.');
      }
      const data = await response.json();
      setContactDetails({
        phone: data.data[0]?.phone || '',
        email: data.data[0]?.email || '',
        address: data.data[0]?.address || '',
        googleMapLink: data.data[0]?.googleMapIframe || ''
      });
    } catch (err) {
      // console.error(err);
      // Handle error, e.g., show a toast notification
    }
  };

  const fetchAboutData = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
      const data = await response.json();
      setAboutData(data);
    } catch (error) {
      console.error('Error fetching About Us data:', error);
    }
  };

  useEffect(() => {
    fetchAllProduct();
    fetchContactDetails();
    fetchAboutData();
  }, []);

  const pageTitle = `All Products | ${aboutData?.companyName || ''}`;
  const pageDescription = `Browse through a wide selection of ${allProduct.length} products in categories. Address: ${contactDetails.address || ''}`;

  const displayINRCurrency1 = (num) => {
    const extractedData = extractNumberAndText(num);
    const extractedNumber = extractedData ? extractedData.number : num;
    const extractedText = extractedData ? extractedData.text : '';

    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    });

    return `Starting From: ${formatter.format(extractedNumber)} ${extractedText}`;
  };

  function extractNumberAndText(input) {
    const regex = /^(\d+)\s*(.*)$/;
    const match = input.match(regex);
    if (match) {
      return { number: match[1], text: match[2] };
    }
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`https://builder.biztoindia.in/${allProduct[0]?.productImage[0] || '/default-image.jpg'}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta
          name="twitter:image"
          content={`https://builder.biztoindia.in/${allProduct[0]?.productImage[0] || '/default-image.jpg'}`}
        />
      </Helmet>

      <div className="container mx-auto p-4  font-lub" style={{ backgroundColor }}>
        <p className="text-2xl font-bold mb-4 text-center" style={{color:primaryColor}}>Choose By Category</p>
        <CategoryList backgroundColor={backgroundColor} secondaryColor={secondaryColor} primaryColor={primaryColor} />
        <div className="h-3 w-full rounded-md" style={{ backgroundColor: primaryColor }}></div>
        <h1 className="text-2xl font-bold mb-4 text-center mt-3" style={{color:primaryColor}}>All Products</h1>

        {allProduct.length === 0 ? (
          <div className="text-center text-gray-600 py-10 px-6   rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">We Are Updating Our Product</h2>
            <p className="mb-4">We are currently updating our product collection. Please check back soon for products!</p>
            <img src={maintenanceImage} alt="Gallery Under Maintenance" className="w-full h-auto rounded-lg shadow-md" />
          </div>
        ) : (
          <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {allProduct?.map((product) => (
              <div key={product?.slug}>
                <Link
                  to={`/product/${product?.slug}`}
                  className= " rounded-lg p-4 transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <div className="bg-slate-200 h-48 p-4 min-w-[280px] flex justify-center items-center">
                    <img
                      src={`https://builder.biztoindia.in/${product.productImage[0]}`}
                      alt={product?.productName}
                      title={product?.productName}
                      className="object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply"
                    />
                  </div>
                  <div className="p-4 grid gap-3">
                    <h3 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1" style={{color:primaryColor}}>
                      {product?.productName}
                    </h3>
                    <p className="capitalize" style={{color:secondaryColor}}>{product?.category}</p>
                    {product?.isPriceVisible && (
                      <div className="flex gap-3">
                        <p className="font-medium" style={{ color: secondaryColor }}>
                          {displayINRCurrency1(product?.price)}
                        </p>
                      </div>
                    )}
                  </div>
                </Link>
                <div onClick={(e) => e.preventDefault() && e.stopPropagation()}>
                  <ContactForm className="  rounded" product={product} primaryColor={primaryColor} backgroundColor={backgroundColor}/>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ShowAllProducts;
